import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "../../styles/device";
import Logo from "../Logo/Logo";

import { useQuery, gql } from "@apollo/client";
import BoozeCard from "./BoozeCard";

const GET_BOOZE = gql`
  query GetBooze {
    drinks {
      name
      abv
      cost
      type
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TypeContainer = styled.div`
  margin: 1rem;
  border-bottom: 1px solid grey;

  padding: 1rem;
`;

export default function Booze() {
  const { loading, error, data } = useQuery(GET_BOOZE);

  const [drinks, setDrinks] = useState(null);

  useEffect(() => {
    let drinksByType = {
      cider: [],
      bottles: [],
      draught: [],
      wine: [],
      cocktails: [],
      softs: [],
      hot: [],
    };
    if (data && data.drinks && data.drinks.length > 0) {
      data.drinks.forEach((drink) => {
        drinksByType[drink.type].push(drink);
      });
      setDrinks(drinksByType);
    }
  }, [data]);

  if (error) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{ marginTop: "1rem" }} className="tac">
          Our booze page is down for maintenance.
        </p>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <Logo />
      </div>
    );
  } else
    return (
      <ColumnContainer>
        {drinks &&
          Object.keys(drinks).map((type) => {
            if (drinks[type].length > 0) {
              return (
                <TypeContainer key={type}>
                  <h2 className="underline" key={type}>
                    {type == "bottles"
                      ? "In the fridge"
                      : type.substring(0, 1).toUpperCase() + type.substring(1)}
                  </h2>
                  {drinks[type].map(({ name: drinkName, abv, cost }) => (
                    <BoozeCard
                      name={drinkName}
                      abv={abv}
                      cost={cost}
                      key={drinkName}
                    ></BoozeCard>
                  ))}
                </TypeContainer>
              );
            } else return <></>;
          })}
      </ColumnContainer>
    );
}
