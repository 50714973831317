import React, { useRef, useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import Events from "./components/Events/Events";
import {
  Grid,
  Main,
  MainContentContainer,
  ContentContainer,
} from "./styles/AppStyles";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import Navbar from "./components/Navbar/Navbar";
import Logo from "./components/Logo/Logo";
import Footer from "./components/Footer/Footer";
import Venue from "./components/Venue/Venue";
import Contact from "./components/Contact";
import Booze from "./components/Booze/Booze";

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_ADDRESS,
  cache: new InMemoryCache(),
  include: "include",
});

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function App() {
  const size = useWindowSize();

  return (
    <BrowserRouter>
      <Grid>
        <MainContentContainer>
          <Logo />
          <Navbar />
          <Main>
            <Route
              render={({ location }) => {
                return (
                  <TransitionGroup component={null}>
                    <CSSTransition
                      key={location.key}
                      timeout={450}
                      classNames="fade"
                    >
                      <Switch>
                        <Route exact path="/">
                          <ApolloProvider client={client}>
                            <ContentContainer>
                              <Events size={size} />
                            </ContentContainer>
                          </ApolloProvider>
                        </Route>
                        <Route exact path="/booze">
                          <ApolloProvider client={client}>
                            <Booze />
                          </ApolloProvider>
                        </Route>
                        <Route exact path="/venue">
                          <Venue />
                        </Route>
                        <Route exact path="/contact">
                          <Contact />
                        </Route>
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                );
              }}
            />
          </Main>
          <Footer size={size} />
        </MainContentContainer>
      </Grid>
    </BrowserRouter>
  );
}

export default App;
