import React from "react";

export default function BoozeCard({ name, abv, cost, type }) {
  return (
    <p>
      {name}
      {/* {name} {abv && <>~ {abv}%</>} ~ {cost} */}
    </p>
  );
}
