import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useQuery, gql } from "@apollo/client";
import { EventCard } from "./EventCard";
import EventsModal from "./EventsModal";
import { device } from "../../styles/device";
import Logo from "../Logo/Logo";

const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  padding: 1rem;
`;

const CardListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
  align-content: space-between;
  gap: 10px;
`;

const GET_EVENTS = gql`
  query GetEvents {
    events {
      title
      date
      id
      image {
        url
      }
    }
  }
`;

export default function Events({ size }) {
  const { loading, error, data, refetch } = useQuery(GET_EVENTS);

  const [events, setEvents] = useState([]);
  const [isOpen, toggleIsOpen] = useState(false);

  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    if (selectedId !== "") {
      toggleIsOpen(true);
    }
  }, [selectedId]);

  useEffect(() => {
    if (data) {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      let futureEvents =
        data &&
        data.events &&
        data.events.length > 0 &&
        data.events
          .filter((event) => {
            const eventDate = new Date(event.date);
            if (today < eventDate) {
              return true;
            } else return false;
          })
          .sort((eventA, eventB) => {
            return new Date(eventA.date) - new Date(eventB.date);
          });
      setEvents(futureEvents);
    }
  }, [data]);

  if (error) {
    refetch();
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className="tac">Our events page is down for maintenance.</p>
        <a
          href="https://thelubberfiend.bigcartel.com/"
          className="tac"
          style={{ width: "100%" }}
        >
          Head over to Big Cartel for listings and tickets.
        </a>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <Logo />
      </div>
    );
  } else
    return (
      <ColumnContainer>
        <CardListContainer>
          {events &&
            events.length > 0 &&
            events.map((event) => {
              return (
                <EventCard
                  date={event.date}
                  title={event.title}
                  type={event.type}
                  setSelectedId={setSelectedId}
                  id={event.id}
                  key={event.id}
                  toggleIsOpen={toggleIsOpen}
                  image={event.image}
                />
              );
            })}
        </CardListContainer>

        <EventsModal
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
          selectedId={selectedId}
          size={size}
        />
      </ColumnContainer>
    );
}
