import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import dayjs from "dayjs";

const Image = styled.img`
  width: 99%;
  margin: auto;
  border-radius: 5px;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  transition: 200ms;
`;

const OuterContainer = styled.div`
  margin: 0.5rem;
  margin-bottom: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  &:hover {
    transform: translate(0px, -3px);
    box-shadow: "0 8px 10px rgba(1, 0, 1, 0.1), 0 14px 20px rgba(1, 1, 1, 0.3)";
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
  transition: 200ms;
  /* width: 100%; */
  /* background-color: black; */
  border-radius: 3px;
  border: 1px solid lightgray;
`;

const CardContainer = styled.div`
  /* border-radius: 8px; */
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  margin-top: 0rem;
  display: grid;
  max-width: 300px;

  grid-template-columns: 1fr 40px;
  /* background-color: #494949; */
  box-shadow: "0 3px 5px rgba(1, 0, 1, 0.05), 0 7px 10px rgba(1, 1, 1, 0.2)";
  color: rgba(245, 245, 245, 1);
  transition: 200ms;
  width: 100%;
  /* height: 120px; */
`;

const TextAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: "offwhite";
`;

const CardText = styled.div`
  margin: 0.75rem;
  color: rgba(245, 245, 245, 1);
`;

export const EventCard = ({
  title,
  date,
  id,
  setSelectedId,
  type,
  toggleIsOpen,
  image,
}) => {
  return (
    <OuterContainer
      onClick={(e) => {
        e.preventDefault();
        setSelectedId(id);
        toggleIsOpen(true);
      }}
    >
      <CardText>
        <h3 className="tar" style={{ color: "rgba(245, 245, 245, 1)" }}>
          {dayjs(date).format("dddd D MMM | HH:mm")}
        </h3>
      </CardText>
      {image && image.url && (
        // <Image src={process.env.REACT_APP_API_ROOT + image.url} />
        <Image src={image.url} />
      )}
      <CardContainer type={type}>
        <TextAndIconContainer>
          <CardText>
            <div style={{ color: "rgba(245, 245, 245, 1)" }}>
              <h2>{title}</h2>
            </div>
          </CardText>
        </TextAndIconContainer>
      </CardContainer>
    </OuterContainer>
  );
};
